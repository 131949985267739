import { Box, Container, Divider, Grid, Image, rem, Stack, Text, Title } from '@mantine/core';

import PieChart from './PieChart.svg';

export default function HowTo(props) {
  return (
    <Box id={'how-to-invest'}>
      <Container py={{ base: 50, md: 100 }} {...props}>
        <Stack align="center" maw={{ base: 270, sm: 350, md: '100%' }} m={'0 auto'} ta={{ base: 'center', md: 'left' }}>
          <Image src={PieChart} w={64} />
          <Title lh={1.2} fw={500} fz={rem(48)} lts={rem(-3)} c={'gfdark.9'}>
            How to buy shares?
          </Title>
          <Grid grow gutter={'xl'} mt={{ base: 30, md: 100 }}>
            <Grid.Col span={{ md: 3 }}>
              <Stack gap="sm">
                <Text c="gf" fz={48}>
                  1
                </Text>
                <Text c="gfdark.9" fz={rem(20)} lh={1.35}>
                  Create an Account on
                  <br />
                  Goldframer Platform:
                </Text>
                <Divider color={'gfdark.0'} size={'sm'} />
                <Text c="gfdark.5" fz={rem(16)}>
                  Sign up for an account on the Goldframer platform to get started.
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={{ md: 3 }}>
              <Stack gap="sm">
                <Text c="gf" fz={48}>
                  2
                </Text>
                <Text c="gfdark.9" fz={rem(20)} lh={1.35}>
                  Fund Your
                  <br />
                  Wallet
                </Text>
                <Divider color={'gfdark.0'} size={'sm'} />
                <Text c="gfdark.5" fz={rem(16)}>
                  Add funds to your secure wallet on our platform. You can deposit and withdraw money at any time, ensuring
                  flexibility and convenience in managing your art investments.
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={{ md: 3 }}>
              <Stack gap="sm">
                <Text c="gf" fz={48}>
                  3
                </Text>
                <Text c="gfdark.9" fz={rem(20)} lh={1.35}>
                  Stay
                  <br />
                  Informed
                </Text>
                <Divider color={'gfdark.0'} size={'sm'} />
                <Text c="gfdark.5" fz={rem(16)}>
                  Get sale alerts via email or join our social groups. Follow us on social media for updates on art investment
                  opportunities and exploring the world of Islamic artworks.
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={{ md: 3 }}>
              <Stack gap="sm">
                <Text c="gf" fz={48}>
                  4
                </Text>
                <Text c="gfdark.9" fz={rem(20)} lh={1.35}>
                  Buy Shares in
                  <br />
                  Artworks
                </Text>
                <Divider color={'gfdark.0'} size={'sm'} />
                <Text c="gfdark.5" fz={rem(16)}>
                  Once artwork sales go live, buy your shares with a click.
                </Text>
              </Stack>
            </Grid.Col>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}
